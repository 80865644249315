import React, { useState } from 'react'
import styled from 'styled-components'
import WhiteArrow from '../../assets/white-arrow.png';
import PageDivider from '../PageDivider';
import ButtonOverlay from '../ButtonOverlay';

const OverviewContainer = styled.div`
    padding-top: 1rem;

    @media (max-width: 415px) {
        padding-top: 0.2rem;

        h1 {
            font-size: 15px;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
`

const OverviewItem = styled.div`
    margin-bottom: 18px;

    @media (max-width: 415px) {
        p {
            font-size: 12px;
            line-height: 14px;
        }
    }
`;

const Title = styled.h2`
    margin-bottom: 5px;

    @media (max-width: 415px) {
        font-size: 14px;
    }
`;

const Button = styled.div`
    font-family: Montserrat;
    background: var(--dark-blue);
    color: var(--white);
    padding: 10px 30px 10px 8px;
    width: 372px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
    position: relative;
    margin-bottom: 34px;

    @media (max-width: 415px) {
        font-size: 14px;
        padding: 5px 30px 5px 8px;
        width: 290px;
        margin-bottom: 20px;
    }

    button {
        background-color: var(--dark-blue);
        border: none;
        padding: 0;
        color: var(--white);
        cursor: pointer;

        &:after {
            content: '';
            background-image: url(${WhiteArrow});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 24px;
            position: absolute;
            top: 10px;
            left: 92%;
            z-index: 1;

            @media (max-width: 415px) {
                height: 18px;
                left: 93%;
                top: 8px;
            }
        }
    }

    :after {
        content: '';
        background-color: var(--pink);
        position: absolute;
        width: 48px;
        margin-left: 19px;
        margin-top: -10px;
        height: 46px;
        border-radius: 4px;

        @media (max-width: 415px) {
            width: 33px;
            height: 33px;
            margin-top: -5px;
        }
    }
`;

const SupportContainer = styled.div`
    margin-bottom: 5px;
    background: linear-gradient(to right,#e6e7e8 0,#fff 10%, #fff 90%, #e6e7e8 100%);
    padding: 0.5rem 1.5rem;
    width: calc(100% + 50px);
    transform: translateX(-25px);

    @media (max-width: 415px) {
        width: 100%;
        transform: initial;
    }

    h2 {
        text-align: center;
        margin-bottom: 0;
        @media (max-width: 415px) {
            font-size: 14px;
        }
    }

    sub {
        bottom: 0;
    }
`;

const HcpBodyContent = () => {
    const [overlay, setOverlay] = useState(() => {
        return false;
    });

    const openButtonOverlay = () => {
        setOverlay(true);
    }

    const closeButtonOverlay = () => {
        setOverlay(false);
    }

    return (
    <>

    {overlay && 
        <ButtonOverlay openButtonOverlay={openButtonOverlay} closeButtonOverlay={closeButtonOverlay}/>
    }

    <OverviewContainer>
        <h1>Overview of The Organon Access Program</h1>

        <OverviewItem>
            <Title>Benefit Investigations</Title>
            <p>
                The Organon Access Program can contact insurers to request coverage and benefits information. Visit the specific product site for additional resources.
            </p>
            <PageDivider/>
        </OverviewItem>

        <OverviewItem>
            <Title>Prior Authorizations</Title>
            <p>
                If a prior authorization is required, or for assistance in understanding if a prior authorization is required, The Organon Access Program may be able to help.
            </p>
            <p>
                The prior authorization checklist and sample letter can help you to understand the documents and information that may be helpful when seeking a prior authorization. As always, you should check for payer-specific requirements.
            </p>
            <PageDivider/>
        </OverviewItem>

        <OverviewItem>
            <Title>Appeals</Title>
            <p>
                If you have submitted a claim and the claim has been denied, you can submit an appeal to your patient’s insurer.
            </p>
            <p>
                The Organon Access Program may be able to help your office understand the information needed for an appeal submission.
            </p>
            <p>
                The appeal checklist and sample appeal letter can help you to understand the documents and information that may be helpful when filing an appeal. As always, you should check for payer-specific requirements.
            </p>
            <PageDivider/>
        </OverviewItem>

        <OverviewItem>
            <Title>The Organon Co-pay Assistance Program</Title>
            <p>
                The Organon Co-pay Assistance Program offers assistance to eligible privately insured patients who need help affording the out-of-pocket costs for their medication.
            </p>
            <p>
                Co-pay assistance from the Organon Co-pay Assistance Program is not insurance. Restrictions apply. See the Terms and Conditions for the applicable product program.
            </p>
            <p>
                Patient and health care professional must submit all required information. Please see the enrollment form for the applicable product for details.
            </p>
            <PageDivider/>
        </OverviewItem>

        <OverviewItem>
            <Title>Independent Assistance Foundations</Title>
            <p>
                Your patients may also be able to get help from an independent assistance foundation. Independent assistance foundations may be able to help patients who are encountering hardships affording their prescribed medications or who do not qualify for other forms of financial support. Each foundation determines its own eligibility criteria and application process and is independent from Organon; there is no guarantee a patient will receive any type of assistance from these organizations.
            </p>
            <PageDivider/>
        </OverviewItem>

        <OverviewItem>
            <Title>The Organon Patient Assistance Program</Title>
            <p>
                The Organon Access Program representatives can refer patients to the Organon Patient Assistance Program for eligibility determination (provided through the Organon Patient Assistance Program Inc.)
            </p>
            <PageDivider/>
        </OverviewItem>

        <Button>
            <button onClick={() => { return openButtonOverlay() }}>
                Click Here for More Information
            </button>
        </Button>
        <SupportContainer>
            <h2>Representatives are available to answer your questions Monday through Friday from 8 <sub>AM</sub> – 8 <sub>PM</sub> ET.</h2>
        </SupportContainer>
    </OverviewContainer>
    </>
    )
}


export default HcpBodyContent;