import React from "react"
import { Container } from "../../components/layout/layoutComponents"
import Layout from "../../components/layout"
import SupportCallout from '../../components/SupportCallout'
import HcpSupportProcess from '../../components/HcpSupportProcess'
import ListCallout from '../../components/ListCallout'
import EnrollCallout from '../../components/EnrollCallout'
import ButtonOverlay from '../../components/ButtonOverlay'
import HcpBodyContent from '../../components/HcpBodyContent'
import HcpHeader from "../../components/HcpHeader"
import Footer from "../../components/Footer"
import { ComponentWrapper } from "../../components/layout/ComponentWrapper"

const Index = () => (
  <>
  <HcpHeader />
  <ComponentWrapper>
  <Layout>
    <Container>
      <SupportCallout/>
      <HcpSupportProcess/>
      <HcpBodyContent/>
      <ListCallout/>
      <EnrollCallout/>
    </Container>
  </Layout>
  </ComponentWrapper>
  <Footer/>
  </>
)

export default Index
