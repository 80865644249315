import React from 'react'
import styled from 'styled-components'
import Icon1 from "../../assets/hcp-help-benefit.png";
import Icon2 from "../../assets/con-mobile-hero-copay.png";
import Icon3 from "../../assets/con-mobile-hero-referral.png";
import Icon4 from "../../assets/hcp-help-billing.png";
import PageDivider from '../PageDivider';
import ListPipe from '../../assets/list-pipe.png';

const ComponentWrapper = styled.div`
    margin: 20px 0 10px 0;
`;

const Title = styled.h2`
    color: var(--dark-blue);
    font-weight: 400;
    font-size: 18px;

    @media(max-width: 415px) {
        font-size: 14px;
        font-weight: 600;
    }
`;

const ProcessContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media(max-width: 415px) {
        flex-wrap: wrap;
        flex-direction: row;
    }
`;

const ProcessBox1 = styled.div`
    display: flex;
    flex-basis: 50%;
    justify-content: space-evenly;
    
    @media(max-width: 415px) {
        flex-direction: column;
    }
`;

const ProcessBox2 = styled.div`
    display: flex;
    flex-basis: 50%;
    justify-content: space-evenly;
    
    @media(max-width: 415px) {
        flex-direction: column;
    }
`;

const ProcessItem = styled.div`
    flex: 1;
    &:last-child {
        margin-right: -8px;
    }

    @media(max-width: 415px) {
        :nth-child(1) {
            min-height: 185px;
        }
    }
`;

const ProcessIconOne = styled.div`
    width: 60px;
    height: 50px;
    background-image: url(${Icon1});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 70px;
        height: 56px;
    }
`;

const ProcessIconTwo = styled.div`
    width: 40px;
    height: 50px;
    background-image: url(${Icon2});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 50px;
        height: 56px;
    }
`;

const ProcessIconThree = styled.div`
    width: 60px;
    height: 50px;
    background-image: url(${Icon3});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 90px;
        height: 56px;
    }
`;

const ProcessIconFour = styled.div`
    width: 45px;
    height: 50px;
    background-image: url(${Icon4});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        height: 56px;
    }
`;

const ProcessDetail = styled.p`
    font-size: 14px;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    color: var(--dark-blue);

    @media(max-width: 415px) {
        max-width: 160px;
        font-size: 12px;
        line-height: 14px;
    }

    @media(max-width: 775px) {
        padding: 5px;
        width: 170px;
    }
`;

 const StyledListPipe = styled.div`
    width: 2px;
    height: 180px;
    background-repeat: no-repeat;
    background: linear-gradient(1.0turn, #d9f0f9, #229fd9, #d9f0f9);
    @media(max-width: 415px) {
        display: none;
    }
`;

const SupportProcess = () => (
    <ComponentWrapper>
        <Title>
            The Organon Access Program may be able to help answer questions about: 
        </Title>
        <ProcessContainer>
            <ProcessBox1>
                <ProcessItem>
                    <ProcessIconOne/>
                    <ProcessDetail>
                        Benefit investigations and information about prior authorizations and appeals 
                    </ProcessDetail>
                </ProcessItem>
                <StyledListPipe/>
                <ProcessItem>
                    <ProcessIconTwo/>
                    <ProcessDetail>
                        Co-pay assistance for eligible patients
                    </ProcessDetail>
                </ProcessItem>
                <StyledListPipe/>
            </ProcessBox1>
            <ProcessBox2>
                <ProcessItem>
                    <ProcessIconThree/>
                    <ProcessDetail>
                        Referral to the Organon Patient Assistance Program for eligibility determination (provided through the Organon Patient Assistance Program Inc.) 
                    </ProcessDetail>
                </ProcessItem>
                <StyledListPipe/>
                <ProcessItem>
                    <ProcessIconFour/>
                    <ProcessDetail>
                        Billing and coding
                    </ProcessDetail>
                </ProcessItem>
            </ProcessBox2>
        </ProcessContainer>
        <PageDivider/>
    </ComponentWrapper>
)

export default SupportProcess;