import React from "react"
import styled from "styled-components"
import CloseBtn from '../../assets/close-btn.png';

const ButtonOverlay = ({ openButtonOverlay, closeButtonOverlay }) => {
    return(
    <OverlayWrapper>
        <OverlayContainer>
            <OverlayClose
                onClick={closeButtonOverlay}
            />
            <OverlayHeader>
                Who is the Organon Patient Assistance Program for?
            </OverlayHeader>
            <OverlayBody>
                Patients who do not have insurance or whose insurance does not cover their medication may be eligible for free product from the Organon Patient Assistance Program if they meet financial and medical criteria.
            </OverlayBody>
            <OverlayInfo>
                For more information on the program’s eligibility requirements, please visit <a href="https://organonhelps.com/" target="_blank">organonhelps.com</a>
            </OverlayInfo>
        </OverlayContainer>
    </OverlayWrapper>
    )
}

const OverlayWrapper = styled.div`
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    align-content: center;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 22px;
`
const OverlayContainer = styled.div`
    background-color: var(--white);
    position: absolute;
    width: 50%;
    max-width: 600px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    @media (max-width: 415px) {
        width: 90%;
    }
`

const OverlayClose = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    width: 35px;
    background-image: url(${CloseBtn});
    background-color: var(--pink);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    cursor: pointer;
`

const OverlayHeader = styled.div`
    color: var(--dark-blue);
    font-weight: bold;
    padding: 2rem 1rem 1rem;

    @media (max-width: 415px) {
        padding: 1rem 1rem 1rem;
    }
`

const OverlayBody = styled.div`
    color: var(--black);
    padding: 0rem 1rem 1rem;
`

const OverlayInfo = styled.div`
    background-color: var(--pink);
    color: var(--white);
    padding: 0.5rem 1rem;

    a {
        font-weight: bold;
        color: var(--white);
    }
`


export default ButtonOverlay